@value unit: var(--ring-unit);

.sidebar {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.sidebarContent {
  margin-top: 0;
}

.header {
  padding: calc(unit * 1.5) calc(unit * 0.5) calc(unit * 1.5) calc(unit * 2);
}

.searchWrapper {
  flex: 1;

  padding: 0;
}

@value dark from '@jetbrains/ring-ui/components/global/variables_dark.css';
@value font-smaller-lower from '../../../ring-globals.css';
@value default-branch-background-color: var(--tc-default-branch-background-color);
@value unit: var(--ring-unit);

:root {
  --tc-default-branch-background-color: #8ccbff;
}

:global(.ring-ui-theme-dark),
.dark {
  --tc-default-branch-background-color: #0d5087;
}

.buildBranch {
  display: inline-block;

  box-sizing: border-box;
  max-width: 100%;
  height: 20px;
  padding: 0 unit;

  user-select: text;
  transition:
    color var(--ring-fast-ease),
    background-color var(--ring-fast-ease),
    box-shadow var(--ring-fast-ease);
  white-space: nowrap;

  border-radius: var(--ring-border-radius);
  background-color: var(--ring-selected-background-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: normal;
  line-height: var(--ring-line-height);
}

.low {
  composes: font-smaller-lower;
}

.buildBranchContent {
  display: inline-flex;

  width: 100%;
}

.link {
  color: var(--ring-link-color);
}

.autoTrim {
  max-width: 100%;
}

.defaultTrim {
  max-width: calc(unit * 23);
}

.withBorder {
  box-shadow: 0 0 0 1px var(--ring-borders-color) inset;
}

.withIcon {
  color: var(--ring-text-color);
}

.main {
  color: var(--ring-text-color);
  background-color: default-branch-background-color;
}

.main,
.link:hover,
.link:focus {
  box-shadow: none;

  & *::selection {
    color: var(--ring-content-background-color);
    background-color: var(--tc-highlight-color);
  }
}

.link:focus,
.link:hover:hover {
  color: var(--ring-white-text-color);
  outline: none;
  background-color: var(--ring-main-color);
}

.link:hover .innerLink,
.link:focus .innerLink {
  border-bottom-width: 0;
}

.icon {
  margin-right: calc(unit / 8);

  user-select: auto;
}

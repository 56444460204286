@value timeWidth: 58px;
@value unit: var(--ring-unit);

.messages {
  font-family: var(--ring-font-family-monospace);
  font-size: var(--ring-font-size-smaller);

  line-height: var(--ring-line-height-lower);
}

.messageItem.messageItem {
  display: block;
}

.grid {
  outline: none;
  background-color: var(--ring-content-background-color);
}

.time {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  padding-left: calc(unit * 2);
}

.message {
  margin-left: calc(unit * 2 + timeWidth);
}

.runningMessage {
  margin-top: calc(unit * 2);
}

.actions {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  visibility: hidden;
}

.pageTarget {
  & .time {
    padding-left: var(--tc-page-padding-inline);
  }

  & .message {
    margin-left: calc(var(--tc-page-padding-inline) + timeWidth);
  }
}

.jobDetailsSidebarTarget {
  & .time {
    left: calc(unit * 3);

    padding-left: unit;
  }

  & .message {
    margin-left: calc(unit * 4 + timeWidth);
  }
}

.jobDetailsPopupTarget {
  & .actions {
    left: 0;
  }

  & .time {
    left: calc(unit * 3);

    padding-left: unit;
  }

  & .message {
    margin-left: calc(unit * 4 + timeWidth);
  }
}

.messageFocusWrapper:hover,
.messageFocusWrapper:focus {
  & .actions {
    visibility: visible;
  }
}

.hoverDisabled {
  & .messageFocusWrapper:hover {
    background-color: inherit;
  }
}

.jobDetailsPopupTarget,
.jobDetailsSidebarTarget {
  & .grid {
    background: var(--ring-secondary-background-color);
    box-shadow: 0 -1px 0 0 var(--ring-line-color);
  }

  & .stepHeading {
    box-sizing: border-box;
    padding-top: unit;

    background: var(--ring-content-background-color);

    box-shadow:
      0 -1px 0 0 var(--ring-line-color),
      0 1px 0 0 var(--ring-line-color);

    /* stylelint-disable-next-line selector-max-specificity */
    &.stepHeading:focus {
      background-color: var(--ring-hover-background-color);
      box-shadow:
        0 -1px 0 0 var(--ring-line-color),
        0 1px 0 0 var(--ring-line-color),
        inset 2px 0 var(--ring-main-color);
    }

    & .actions {
      visibility: visible;
    }

    & .actions,
    & .time {
      top: unit;
    }

    & .message {
      font-weight: bold;
    }
  }
}

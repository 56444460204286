.close.close {
  position: absolute;
  top: calc(var(--ring-unit) * 0.75);
  right: var(--ring-unit);

  visibility: hidden;

  margin-right: 0;

  transition: opacity var(--ring-ease);

  opacity: 0;

  background-color: var(--ring-hover-background-color);
}

.filter {
  &.activeFilter {
    .close {
      visibility: visible;

      opacity: 1;
    }
  }

  &:hover {
    .close {
      visibility: visible;

      opacity: 1;
    }
  }
}

.filter.filter {
  padding: 0 calc(var(--ring-unit) * 1.5) 0 var(--ring-unit);

  color: var(--ring-text-color);

  border-radius: calc(var(--ring-unit) / 2);
  background-color: var(--ring-disabled-background-color);

  &:hover {
    color: var(--ring-main-color);
    background-color: var(--ring-hover-background-color);
  }

  &:active {
    color: var(--ring-main-color);
    background-color: var(--ring-hover-background-color);
  }

  &.activeFilter {
    color: var(--ring-main-color);
    background-color: var(--ring-hover-background-color);
  }
}

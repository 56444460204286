.stopBuild {
  white-space: nowrap;
}

.icon {
  height: auto;

  line-height: inherit;
}

.stopIcon {
  vertical-align: -1px;
}

.iconOnly.iconOnly {
  color: var(--ring-icon-error-color);
}

.iconOnly :global(.secondary) {
  fill: var(--ring-removed-subtle-background-color);
}

.invisible {
  display: none;
}

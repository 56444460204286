@value unit: var(--ring-unit);

.actionButton {
  transition: opacity var(--ring-ease);

  opacity: 0;
}

.container {
  display: flex;

  margin-right: calc((unit / 2) * -1);

  padding: unit 0;

  cursor: pointer;

  &:hover .actionButton {
    opacity: 1;
  }
}

.editButtonIcon {
  margin-top: 4px;
}

.defaultBranch {
  display: flex;
  align-items: start;
  flex: 1;

  padding-left: unit;

  &:hover .editButtonIcon {
    color: var(--ring-link-hover-color);
  }
}

.branch,
.vcsStorePreview {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.branchRules {
  display: flex;
  align-items: baseline;

  padding-bottom: calc(unit * 0.5);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.icon {
  fill: var(--ring-text-color);

  margin-top: 2px;
}

.repository {
  flex: 1;
}

.deleted {
  cursor: default;
  text-decoration-line: line-through;

  color: var(--ring-secondary-color);

  .icon {
    opacity: 0.5;
  }
}

@value unit: var(--ring-unit);

.item,
.heading {
  display: contents;
}

.button {
  &::before {
    position: absolute;
    top: 0;
    right: calc(var(--tc-page-padding-inline) * -1);
    bottom: 1px;
    left: calc(var(--tc-page-padding-inline) * -1);

    content: '';
  }

  grid-column: start / end;
  grid-row: var(--row) / span 1;
}

.open {
  &:not(.groupedTest)::before {
    position: absolute;

    z-index: 1;
    top: var(--absolute-child-top);
    right: calc(var(--tc-page-padding-inline) * -1);
    bottom: 0;
    left: calc(var(--tc-page-padding-inline) * -1);

    content: '';
    pointer-events: none;

    box-shadow:
      0 0 0 1px var(--ring-line-color),
      0 2px 8px var(--ring-popup-shadow-color);

    grid-column: start / end;
    grid-row: var(--row) / span 2;
  }
}

.button:focus {
  outline: none;

  &::before {
    box-shadow: inset 2px 0 var(--ring-main-color);
  }
}

.closed:hover .button,
.closed .button:focus {
  /* stylelint-disable-next-line selector-max-specificity */
  &::before {
    background-color: var(--ring-hover-background-color);
  }
}

.detailsWrapper {
  grid-column: start / end;
}

.row {
  display: contents;
}

.arrow {
  position: absolute;

  grid-column: -2;
  grid-row: var(--row) / span 1;

  top: calc(var(--absolute-child-top) + 8px);
  right: calc(unit * -4 + 10px);
  left: auto;

  padding: 0;

  transition: opacity ease;

  pointer-events: none;

  opacity: 0;
  color: var(--ring-icon-secondary-color);

  line-height: normal;

  .item:hover &,
  .button:focus ~ & {
    transition: none;

    opacity: 1;
  }

  .button:active ~ & {
    transition: none;

    opacity: 1;
    color: var(--ring-main-color);
  }
}

.outerContainer {
  position: relative;

  overflow: hidden;

  height: calc(unit * 11);
  margin: 0 calc(var(--tc-page-padding-inline) * -1);
}

.innerContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  padding: 0 var(--tc-page-padding-inline);
}

.details {
  padding: calc(unit * 2) 0;
}

.statusColumn {
  grid-column-start: status-start;
}

.durationColumn {
  grid-column-start: duration-start;

  text-align: right;
}

.buildColumn {
  grid-column-start: build-start;
}

.buildDetailsColumn {
  display: flex;
  flex-direction: column;
  grid-column-start: build-details-start;

  & > div {
    display: flex;
    align-items: baseline;
  }
}

.changesColumn {
  grid-column-start: changes-start;
}

.buildAgentLink {
  font-size: var(--ring-font-size);
}

.changesColumn .buildAgentLink {
  display: none;
}

.agentColumn {
  grid-column-start: agent-start;
}

@media (width <= 1400px) {
  .changesColumn .buildAgentLink {
    display: inline-flex;
  }

  .agentColumn {
    display: none;
  }
}

.buildStartDateColumn {
  grid-column-start: start-date-start;

  padding: 0;

  text-align: right;
  white-space: nowrap;
}

.actionsColumn {
  grid-column-start: actions-start;

  display: flex;
  justify-content: flex-end;

  padding: 0;
}

.showInBuildLogIcon {
  height: var(--ring-line-height);
  margin: 0 calc(unit * -1);

  line-height: var(--ring-line-height);
}

.statusColumn,
.durationColumn,
.buildColumn,
.buildDetailsColumn,
.changesColumn,
.agentColumn,
.buildStartDateColumn,
.actionsColumn {
  grid-row-start: var(--row);

  z-index: 2;

  overflow: hidden;

  padding: 6px 0;

  line-height: var(--ring-line-height);
}

.buildPath {
  flex: 1;

  margin-right: 0;
}

.buildBranch,
.buildPath,
.buildNumber,
.buildStatus {
  margin-right: unit;

  line-height: var(--ring-line-height);

  &:last-child {
    margin-right: 0;
  }
}

.buildNumber > a {
  color: var(--ring-text-color);

  font-size: var(--ring-font-size);
}

.separator {
  flex-shrink: 0;

  color: var(--ring-secondary-color);
}

@value unit: var(--ring-unit);

.queuedWrapper {
  padding: calc(unit / 2) 0;

  text-align: center;

  border-bottom: 1px solid var(--ring-line-color);

  background-color: var(--ring-secondary-background-color);
}

.queuedButton {
  width: 100%;

  color: var(--ring-secondary-color);

  &:hover {
    color: var(--ring-link-hover-color);
  }
}

.noBuilds {
  composes: noBuildTypes from '../../pages/ProjectPage/ProjectOverviewTab/ProjectOverviewTab.css';

  grid-column: start / end;

  margin: 1px calc(unit * -2) 2px;
}

.content {
  margin-bottom: calc(unit * 1.5);

  border: 1px solid var(--ring-line-color);
  border-radius: var(--ring-border-radius);
  background: var(--ring-content-background-color);
}

.buildListWrapper.buildListWrapper {
  margin-bottom: -1px;
  padding: 0 calc(unit * 2);
}

.hasParentGrid {
  display: contents;
}

.content.hasParentGrid::before {
  position: absolute;
  top: calc(var(--absolute-child-top) - 1px);
  right: calc(unit * -2 - 1px);
  bottom: 0;
  left: calc(unit * -2 - 1px);

  content: '';
  pointer-events: none;

  border: 1px solid var(--ring-line-color);
  border-radius: var(--ring-border-radius);
  grid-column: start / end;
  grid-row: var(--row) / span var(--row-span);
}

.withQueued {
  composes: withQueued from './BuildDetails/BuildDetails.css';
}

.placeholder {
  width: 100%;
  height: 32px;

  border: 1px solid black;
}

.queuedToggler {
  border-top-left-radius: var(--ring-border-radius-small);
  border-top-right-radius: var(--ring-border-radius-small);
}

.hasParentGrid .queuedToggler {
  grid-column: start / end;

  margin: 0 calc(unit * -2);
}

.showMoreQueuedBulds {
  grid-column: start / end;

  margin: 0 calc(unit * -2);
}

@value verticalCompensation: 2px;
@value unit: var(--ring-unit);

.item {
  position: relative;

  list-style-type: none;

  cursor: pointer;

  line-height: var(--ring-line-height);
}

.link {
  flex-grow: 1;

  border-radius: 0;
}

.link,
.innerLink {
  color: var(--ring-text-color);
}

.heading,
.link {
  display: flex;
  align-items: baseline;

  margin: 0 calc(var(--tc-page-padding-inline) * -1);
  padding: 6px calc(var(--tc-page-padding-inline) + unit) 6px calc(unit * 4);

  &:hover {
    background: var(--ring-hover-background-color);
  }
}

/* stylelint-disable-next-line */
.interactive {
}

.chevronIcon {
  position: absolute;
  top: 6px;

  transition: color var(--ring-ease);

  color: var(--ring-icon-secondary-color);
}

.name {
  margin-right: 6px;
  margin-left: 6px;
}

.link .name {
  line-height: var(--ring-line-height-lower);
}

.innerLink {
  composes: name;
  composes: interactive;
}

.icon {
  color: var(--ring-icon-color);

  line-height: normal;
}

.size {
  margin-right: unit;

  white-space: nowrap;

  color: var(--ring-secondary-color);
}

.extensionIcon {
  composes: interactive;
}

.compact {
  &.item {
    line-height: calc(unit * 3 - verticalCompensation);
  }

  & .heading,
  & .link {
    margin: 0 calc(unit * -2);
    padding: 0 calc(unit * 2 + unit) verticalCompensation calc(unit * 2);
  }

  & .chevronIcon {
    top: 0;
  }

  & .name {
    margin-right: unit;
    margin-left: calc(unit / 2);
  }
}

/* stylelint-disable selector-max-specificity */
.heading:hover > .chevronIcon,
.item:focus > .heading > .chevronIcon {
  transition: none;

  color: var(--ring-icon-hover-color);
}

.interactive.interactive:hover ~ .chevronIcon,
.interactive.interactive:active ~ .chevronIcon {
  transition: color var(--ring-ease);

  color: var(--ring-icon-secondary-color);
}

.item:active > .heading:hover > .chevronIcon {
  transition: none;

  color: var(--ring-main-color);
}
/* stylelint-enable */

.link:hover {
  text-decoration-line: none;

  & .name {
    text-decoration-line: underline;
  }
}

.jobDetailsSidebarTarget {
  & .heading,
  & .link {
    margin: 0 calc(var(--tc-page-padding-inline) * -1) 0 calc(unit * -2.5);
    padding: 6px calc(var(--tc-page-padding-inline) + unit) 6px calc(unit * 2.5);
  }
}

.item:focus > .heading,
.link.link:focus {
  background: var(--ring-hover-background-color);
  box-shadow: inset 2px 0 var(--ring-main-color);
}

.item:focus,
.link:focus {
  outline: none;
}

@media (resolution >= 2dppx) {
  .link:hover {
    /* noinspection CssNonIntegerLengthInPixels */
    & .name {
      text-decoration-thickness: 0.5px;
    }
  }
}

@value unit: var(--ring-unit);
@value button from '@jetbrains/ring-ui/components/button/button.css';

.actions {
  display: flex;
  align-items: baseline;
  column-gap: calc(unit * 1.5);
}

.actions .button {
  height: calc(unit * 2.5);
  padding: 0;

  line-height: calc(unit * 2.5);
}

.settings {
  width: 100%;
}

.title {
  margin-top: 6px;

  letter-spacing: 1px;
  text-transform: uppercase;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);

  font-weight: 600;
}

.editSettingsForm {
  display: flex;
  flex-direction: column;

  width: 100%;
  row-gap: calc(unit * 1.5);

  &.inline {
    align-items: baseline;
    flex-direction: row;
    column-gap: unit;

    & .actions {
      column-gap: calc(unit * 1.75);
    }
  }

  & .cancelButton {
    color: var(--ring-secondary-color);
  }
}

.deleteButton {
  height: calc(unit * 2.5);
  margin-left: auto;
}

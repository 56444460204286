@value unit: var(--ring-unit);

.cell {
  display: flex;

  height: calc(unit * 3);
  padding: calc(unit * 1.5) 0;
}

.startDate {
  justify-content: end;
}

.duration {
  text-align: end;
}

.status,
.number {
  display: block;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.estimate {
  color: var(--ring-secondary-color);
}

.stop {
  width: calc(unit * 3);
  height: calc(unit * 3);
  padding: 0;

  &:hover {
    color: inherit;
    box-shadow: inset 0 0 0 1px var(--ring-border-hover-color);
  }
}

.stop.stop svg {
  vertical-align: -3px;
}

.stop.queued svg {
  vertical-align: -4px;
}

.branchLabel {
  font-weight: bold;
}

@value unit: var(--ring-unit);

.progressBar {
  position: absolute;
  right: 10px;
  bottom: 2px;
  left: 10px;

  height: 2px;

  background-color: var(--ring-icon-color);
}

@value unit: var(--ring-unit);
@value glyph from '@jetbrains/ring-ui/components/list/list.css';

.container {
  position: absolute;

  border-radius: calc(unit * 1.5);

  background: var(--ring-content-background-color);
}

.label {
  margin-left: calc(unit / 2);
}

.icon {
  & svg {
    width: calc(unit * 2);
    height: calc(unit * 2);
  }
}

.default {
  color: var(--ring-icon-color);
}

.main {
  color: var(--ring-main-color);
}

.clone {
  color: var(--ring-text-color);
}

.deleted {
  color: var(--ring-disabled-color);
}

.warning {
  color: var(--ring-icon-warning-color);
}

.warningMessage {
  color: var(--ring-warning-color);
}

.error {
  color: var(--ring-icon-error-color);
}

.errorMessage {
  color: var(--ring-error-color);
}

.addDependency {
  left: calc(unit * -1.5);

  opacity: 0;
}

.addDependant {
  right: calc(unit * -1.5);

  opacity: 0;
}

.job {
  position: relative;
}

.job:hover .addDependency,
.job:hover .addDependant {
  opacity: 1;
}

.actions {
  z-index: var(--ring-overlay-z-index);

  height: auto;
  margin-right: calc(unit * -1);

  line-height: normal;
}

.delete {
  color: var(--ring-error-color);
}

.delete .glyph {
  color: var(--ring-icon-error-color);
}

.disabled {
  color: var(--ring-disabled-color);
}

.disabled .glyph {
  color: var(--ring-icon-disabled-color);
}

.hasArtifacts {
  color: var(--ring-icon-disabled-color);
}

.tooltip {
  pointer-events: auto;
}

.actionWrapper.actionWrapper {
  padding: 0;
}

@value unit: var(--ring-unit);
@value detailsWidth: calc(unit * 28);
@value desriptionMinWidth: calc(unit * 15);

.container {
  margin: calc(unit * 2) calc(var(--tc-page-padding-inline) * -1) calc(unit * 3);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.nested {
  margin-left: calc(unit * 2.5);

  box-shadow: inset 1px 0 var(--ring-line-color);
}

.star {
  padding: 0;
  padding-right: unit;

  transition: opacity var(--ring-fast-ease);

  opacity: 0;
}

.summaryWrapper:hover .star,
.container:focus-within .star {
  transition: none;

  opacity: 1;
}

.summaryWrapper {
  width: 100%;
}

.parentPath {
  color: var(--ring-secondary-color);
}

.entity {
  z-index: 0;

  display: flex;
  align-items: center;

  flex: 1 1 desriptionMinWidth;

  width: 100%;
  min-width: calc(unit * 10 + desriptionMinWidth);
  max-width: calc(100% - detailsWidth - unit * 3);
}

.entityContainer {
  display: flex;
  overflow: hidden;

  max-width: 100%;
  padding-right: unit;
}

.entityPath.entityPath {
  align-items: center;

  margin-top: calc(unit / 4);
  margin-bottom: calc(unit / 2);

  font-weight: normal;
}

.entityPath {
  display: flex;
  align-items: baseline;

  width: 100%;

  padding: 0;

  letter-spacing: -0.3px;
}

.description {
  flex-shrink: 100;

  min-width: desriptionMinWidth;
  margin-top: 2px;

  font-size: var(--ring-font-size-smaller);
}

.line {
  composes: line from '../../../CollapsibleLine/CollapsibleLine.css';

  box-sizing: border-box;

  margin: 0;
  margin-top: calc(unit / 2);
  margin-bottom: calc(unit / 2);
  padding: 0 var(--tc-page-padding-inline);

  outline: none;

  line-height: var(--ring-line-height-taller);

  &.noChildren {
    cursor: unset;
  }

  &:focus {
    background-color: unset;
  }

  &::before {
    position: absolute;

    z-index: -1;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    margin-top: calc(unit / -4);

    content: '';
  }

  &:hover {
    &::before {
      background-color: var(--ring-hover-background-color);
    }
  }
}

.header {
  composes: row from '../../../CollapsibleLine/CollapsibleLine.css';

  align-items: center;
  justify-content: flex-start;
}

.arrow {
  composes: arrow from '../../../CollapsibleLine/CollapsibleLine.css';

  top: auto;
  bottom: unit;
  left: 6px;
}

.details {
  padding: 0 var(--tc-page-padding-inline) calc(unit / 2);
}

.actions {
  display: flex;
  align-items: baseline;

  align-self: center;
  flex-shrink: 0;
  justify-content: flex-end;

  width: detailsWidth;
  margin-left: auto;

  padding-right: 0;
}

.status {
  display: inline-flex;
  align-items: baseline;
  flex-wrap: nowrap;

  padding-left: calc(unit * 2);

  text-align: right;

  font-size: var(--ring-font-size-smaller);

  &.success {
    color: var(--ring-icon-success-color);
  }

  &.unknown {
    color: var(--ring-secondary-color);
  }

  &.failed {
    color: var(--ring-icon-error-color);
  }
}

.count {
  padding-right: calc(unit / 2);
}

.statusIcon {
  margin-right: 0;
}

.addBuildConfiguration {
  width: calc(unit * 2);

  color: var(--ring-borders-color);
}

.link {
  max-width: calc(100% - unit * 2.5);

  color: var(--ring-text-color);
}

.noChildrenWarning {
  composes: text from '../../../Description/Description.css';

  text-align: right;
}

.minorInfo {
  composes: minorInfo from '../../../Description/Description.css';
}

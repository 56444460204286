@value unit: var(--ring-unit);

.label {
  display: block;

  margin-bottom: calc(unit / 4);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.disabledIcon {
  opacity: 0.5;
}

.repositoryBlock {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit * 1.5);
}

.controls {
  display: flex;
  column-gap: unit;
}

.loginAlertContent {
  display: flex;

  column-gap: unit;
}

.loginLink {
  margin-left: auto;

  text-wrap: nowrap;
}

.form {
  display: flex;
  flex-direction: column;

  margin-top: calc(unit * 1.5);
  row-gap: calc(unit * 1.5);
}

.branchSpecPolicy {
  padding-left: 1px;
}

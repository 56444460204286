@value unit: var(--ring-unit);

.vcsItem {
  display: flex;
  align-items: center;
  column-gap: calc(unit * 1.5);

  padding: calc(unit * 2) calc(unit * 3);

  transition: background-color 0.3s;

  border-top: 1px solid var(--ring-line-color);

  &:first-child {
    border-top: none;
    border-radius: unit unit 0 0;
  }

  &:last-child {
    border-radius: 0 0 unit unit;
  }

  &:hover {
    cursor: pointer;

    background-color: var(--ring-hover-background-color);
  }
}

.vcsName {
  font-size: var(--ring-font-size-larger);
  font-weight: 700;
}

.vcsIcon svg {
  width: calc(unit * 6);
  height: calc(unit * 6);
}

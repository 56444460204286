@value unit: var(--ring-unit);

.container {
  min-width: calc(unit * 25);
}

.wrapper {
  display: grid;
  grid-template-areas:
    'label terminal'
    'name name';
  grid-template-columns: 1fr;
  row-gap: calc(unit / 4);

  /* stylelint-disable selector-max-specificity, selector-id-pattern */
  & :global(#SAKURA_AFTER_AGENT_INFO) {
    justify-content: end;

    margin-top: calc(-0.25 * unit);
    margin-right: calc(-1 * unit);
    grid-area: terminal;

    & button {
      color: var(--ring-link-color);

      &:hover {
        color: var(--ring-link-hover-color);
      }
    }
  }
  /* stylelint-enable selector-max-specificity, selector-id-pattern */

  & .agentInfo {
    display: contents;

    white-space: nowrap;

    font-size: var(--ring-font-size-smaller);
    line-height: var(--ring-line-height-lowest);
  }
}

.agentInfoValue {
  grid-area: name;

  text-overflow: ellipsis;
}

.agentErrorState {
  grid-area: name;

  text-overflow: ellipsis;

  color: var(--ring-warning-color);
}

.icon {
  color: var(--ring-icon-warning-color);
}

.agentWrapperState {
  display: flex;

  align-items: center;

  gap: calc(unit / 2);
}

.label {
  margin-bottom: 6px;
  grid-area: label;

  color: var(--ring-secondary-color);
}

.labelWrapper {
  display: flex;
  justify-content: space-between;
}

.incompatibilityReasonsJobLink {
  font-size: var(--ring-font-size);
}

.incompatibilityReasonsJobLinkIcon {
  margin-left: unit;
}

@value unit: var(--ring-unit);

.container {
  max-width: calc(unit * 70.5);
  padding-top: calc(unit * 12.5);
}

.vcsList {
  margin-top: calc(unit * 3);

  border: 1px solid var(--ring-line-color);
  border-radius: unit;
}

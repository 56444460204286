@value container as tagContainer from '@jetbrains/ring-ui/components/tag/tag.css';
@value unit: var(--ring-unit);

.customLinkIcon {
  margin-right: unit;

  transition: color var(--ring-ease);

  color: var(--ring-icon-color);
}

.tryPipelinesLink {
  display: inline-flex;
}

.tryPipelinesNewTagWrapper {
  margin-left: unit;

  & > .tagContainer {
    display: inline-flex;
    align-items: baseline;
  }
}

@value font-smaller-lower from '../../../ring-globals.css';
@value content from '@jetbrains/ring-ui/components/button/button.css';
@value unit: var(--ring-unit);

.container {
  min-width: calc(unit * 50);
  max-height: calc(unit * 50);
}

.popupContainer {
  max-height: calc(50vh - unit * 4);
}

.multiselect {
  background-color: var(--ring-content-background-color);
  box-shadow: inset 0 0 0 1px var(--ring-line-color);
}

.tree {
  height: 100%;
}

.treeContent {
  flex: 1 0 0;

  height: auto;
  margin-top: unit;
}

.searchWrapper {
  padding: 0 unit 0 calc(unit * 2);

  box-shadow: 0 1px var(--ring-borders-color);
}

.placeholder {
  composes: font-smaller-lower;

  margin: calc(unit * 2) 0 0 calc(unit * 4);

  color: var(--ring-secondary-color);
}

.icon {
  color: inherit;
}

.hint {
  composes: font-smaller-lower;

  box-sizing: border-box;

  min-height: calc(unit * 4);
  padding: unit calc(unit * 2);

  color: var(--ring-secondary-color);

  background-color: var(--ring-secondary-background-color);
  box-shadow: inset 0 1px var(--ring-line-color);
}

.popup {
  z-index: 100;
}

.selectedNote {
  composes: font-smaller-lower;

  margin-top: unit;

  color: var(--ring-secondary-color);
}

.anchorText,
.anchorButton {
  max-width: calc(100% + 6px);

  text-align: start;
  white-space: nowrap;
}

.arrow {
  position: absolute;
  right: 0;

  display: inline;

  padding-right: 8px;
  padding-left: 8px;

  color: var(--ring-icon-secondary-color);
}

.arrowIcon {
  vertical-align: 1px;
}

.anchorButton {
  composes: buttonValue from '@jetbrains/ring-ui/components/select/select.css';

  min-width: calc(unit * 30);

  font-size: var(--ring-font-size);

  & .content {
    display: block;
  }

  &:hover {
    .arrow {
      color: var(--ring-main-color);
    }
  }
}

.anchorButtonOpen {
  composes: buttonValueOpen from '@jetbrains/ring-ui/components/select/select.css';
}

.name:not(:first-child) {
  max-width: calc(100% - unit * 3);
}

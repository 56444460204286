@value unit: var(--ring-unit);

.description {
  padding: 0 10px;
}

.helpIcon {
  margin-left: calc(unit / 2);

  transition: all ease-in 0.1s;

  color: var(--ring-icon-color);
}

.helpIcon:hover {
  transition: none;

  color: var(--ring-icon-hover-color);
}

.form {
  overflow: auto;

  max-height: 50vh;
  padding: 12px 10px 0;
}

.buttonClassName {
  padding-top: 10px;
}

.label,
.hint,
.selectLabelContainer label {
  display: block;
}

.label,
.selectLabelContainer label {
  margin-bottom: calc(var(--ring-unit) * 0.5);

  color: var(--ring-text-color);

  font-size: var(--ring-font-size);
}

.hint {
  margin-top: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.label {
  margin-bottom: calc(unit * 0.5);
}

.parameter {
  padding-bottom: calc(unit * 2);
}

.parameterName {
  padding-bottom: 0;
}

.row {
  display: flex;
  align-items: baseline;

  padding-bottom: unit;
}

.selectRow {
  flex: 1;
}

.deleteButton {
  padding: 0 calc(unit / 2);
}

.popup {
  z-index: 10;
}

.resotre {
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 8px 16px;

  border-radius: 6px;
  background: var(--ring-secondary-background-color);
}

.resotreDeletePermanently {
  margin-left: 12px;

  color: #737577;
}

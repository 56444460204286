@value unit: var(--ring-unit);

.branchSelect.branchSelect {
  height: auto;
  padding: 3px 6px;
  padding-right: 10px;

  color: var(--ring-text-color);

  background-color: var(--ring-hover-background-color);

  font-size: var(--ring-font-size);

  &:hover {
    background-color: var(--ring-selected-background-color);
  }
}

.text {
  min-width: 6ch;

  max-width: calc(unit * 32);

  /* max-width: calc(100% - unit * 6); */
}

.branchIcon {
  color: var(--ring-main-color);
}

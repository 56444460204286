@import '@jetbrains/ring-ui/components/global/variables.css';

@value dark from '@jetbrains/ring-ui/components/global/variables_dark.css';
@value ring: '@jetbrains/ring-ui/components/global/global.css';
@value unit: var(--ring-unit);
@value common from '@jetbrains/ring-ui/components/button-group/button-group.css';

/* TODO remove when fixed in ring-ui */
.common {
  position: relative;
  z-index: 0;
}

/* https://readymag.com/artemtiunov/RingUILanguage/colours/ */

:root {
  --tc-icon-main-color: #444;
  --tc-icon-favorite-icon-color: var(--tc-icon-main-color);
  --tc-message-background-color: rgba(17, 19, 20, 0.9);
  --tc-error-background-color: #faebec;
  --tc-error-hover-color: var(--ring-error-color);
  --tc-success-background-color: #e6f2e9;
  --tc-success-hover-background-color: #bcdcc2;
  --tc-success-progress-color: #7acc8b;
  --tc-success-text-color: var(--ring-text-color);
  --tc-classic-highlight-color: #ffc;
  --tc-changes-graph-background-color: #333;
  --tc-changes-graph-color: #bbb;
  --tc-classic-disabled-input-color: var(--ring-disabled-color);
  --tc-highlight-color: #ff008c;
  --tc-page-padding-inline: calc(unit * 3);
}

:global(.ring-ui-theme-dark),
.dark {
  --tc-icon-favorite-icon-color: var(--ring-icon-color);
  --tc-error-background-color: #4d1f22;
  --tc-error-hover-color: #f8b4b8;
  --tc-success-background-color: #1f4d28;
  --tc-success-hover-background-color: #317c40;
  --tc-success-progress-color: #59a869;
  --tc-success-text-color: var(--ring-white-text-color);
  --tc-classic-highlight-color: #593d01;
  --tc-classic-disabled-input-color: rgba(var(--ring-text-components), 0.5);
  --tc-highlight-color: #e56ab6;

  /* https://youtrack.jetbrains.com/issue/TCP-751/Change-the-color-of-the-icon-to-Text-Color-ring-colour-red */
  --ring-icon-error-color: var(--ring-error-color);
}

/* Metrics */
@value font from ring;
@value font-lower from ring;
@value font-smaller from ring;
@value font-smaller-lower from ring;
@value font-larger-lower from ring;
@value font-larger from ring;
@value thin-font from ring;
@value monospace-font from ring;
@value input-disabled from ring;
@value ellipsis from ring;

/* Note: footer also has top margin which isn't taken into account here */
@value footer-height from ring;

/* Media breakpoints (minimal values) */
@value breakpoint-small from ring;
@value breakpoint-middle from ring;
@value breakpoint-large from ring;

/* Media queries */
@value extra-small-screen-media from ring;
@value small-screen-media from ring;
@value middle-screen-media from ring;
@value large-screen-media from ring;
@value resetButton from ring;

/* https://a11yproject.com/posts/how-to-hide-content/ */

/* stylelint-disable-next-line selector-max-specificity */
.visuallyHidden,
.visuallyHiddenContainer:not(:hover, :global(.focusVisible), :active) .visuallyHiddenChild {
  position: absolute;

  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);

  width: 1px;
  height: 1px;

  white-space: nowrap; /* added line */
}

.lineClamp {
  position: relative;

  overflow: hidden;
  overflow-wrap: break-word;
}

.lineClampHasOverflow::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  padding-top: 1px;

  content: '\2060';

  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

@supports (-webkit-line-clamp: 2) {
  .lineClamp {
    display: -webkit-box;

    -webkit-box-orient: vertical;

    text-overflow: ellipsis;
  }

  .lineClampHasOverflow::after {
    display: none;
  }
}

@value unit: var(--ring-unit);

.button {
  margin-left: unit;
}

.badges {
  & > div {
    max-width: calc(unit * 50);
  }
}

.stopButton {
  padding: 0 unit;

  svg {
    color: var(--ring-icon-error-color);
  }
}

.stopButton :global(.secondary) {
  fill: var(--ring-removed-subtle-background-color);
}

@value unit: var(--ring-unit);

.container {
  position: relative;

  margin: calc(unit * 3) calc((unit * 4) * -1) 0 calc((unit * 2.5) * -1);

  padding: calc(unit * 2) calc(unit * 4);
  padding-left: calc(unit * 2.5);

  border-top: calc(unit / 2) solid var(--ring-sidebar-background-color);
}

.title {
  padding-bottom: unit;

  color: var(--ring-text-color);

  font-size: var(--ring-font-size);
  font-weight: 600;
}

@value unit: var(--ring-unit);

.sidebar {
  z-index: var(--ring-alert-z-index);

  width: 100%;
  height: 100%;
  min-height: 100%;

  background-color: var(--ring-content-background-color);
}

.content {
  min-height: calc(100% - calc(unit * 8));

  & .textInfo {
    padding: calc(unit * 1.5) 0 0 calc(unit * 2);
  }
}

.selectRunner {
  border-bottom: calc(unit / 2) solid var(--ring-sidebar-background-color);
}

.stepContainer {
  position: relative;

  padding: calc(unit * 2) calc(unit * 4);
  padding-left: calc(unit * 2.5);
}

.inputName {
  margin-bottom: unit;
}

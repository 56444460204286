@value unit: var(--ring-unit);

.vcsSelect.vcsSelect {
  width: 100%;
  max-width: calc(unit * 22.5);

  padding-right: unit;
  padding-left: unit;

  color: var(--ring-text-color);

  font-size: var(--ring-font-size);

  & > span {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.vcsIcon.vcsIcon {
  margin-right: calc(unit / 2);

  color: var(--ring-text-color);
}

.vcsSelectItem svg {
  color: var(--ring-text-color);
}

@value unit: var(--ring-unit);

.page {
  display: flex;
  flex: 1;
  flex-direction: column;

  min-height: calc(unit * 50);
  margin: 0 calc(var(--tc-page-padding-inline) * -1);
}

.jobDetailsSidebarTarget {
  overflow: hidden;
  flex: 1;

  margin-bottom: calc(unit * -2);
  margin-left: calc(unit * -2.5);
}

.log {
  flex: 1;
}

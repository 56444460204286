@value verticalCompensation: 2px;
@value unit: var(--ring-unit);

.downloadIcon {
  color: var(--ring-icon-color);
}

.downloadLink {
  z-index: var(--ring-overlay-z-index);
}

@import '@jetbrains/ring-ui/components/header/header.css';
@import '../../common/Avatar/Avatar.css';

@value container as tagContainer from '@jetbrains/ring-ui/components/tag/tag.css';
@value dark from '@jetbrains/ring-ui/components/global/variables_dark.css';
@value actionLink, hint from '@jetbrains/ring-ui/components/list/list.css';
@value hover as linkHover from '@jetbrains/ring-ui/components/link/link.css';
@value unit: var(--ring-unit);

.container {
  position: fixed;
  z-index: calc(var(--ring-fixed-z-index) + 1); /* has to be above the subnavigation */
  top: 0;
  bottom: 0;
  left: 0;

  overflow-x: hidden;

  overflow-y: auto;

  min-width: calc(var(--ring-unit) * 8);

  background-color: var(--ring-navigation-background-color);
}

/* inner theme provider */
.container > div {
  height: 100%;
}

html.dark .container,
html:global(.ring-ui-theme-dark) .container {
  box-shadow: inset -1px 0 var(--ring-line-color);
}

.header {
  align-items: center; /* TODO remove when updated in Ring */

  width: calc(unit * 8);

  background-color: transparent;
}

.text {
  color: var(--ring-text-color);
}

.tray {
  flex: 1;
  justify-content: flex-end;

  margin: 0;
  justify-items: flex-end;
}

.tray.tray {
  align-items: center; /* TODO remove when updated in Ring */

  padding-top: unit;
}

.popup .actionLink {
  color: inherit;
}

.popup .actionLink:hover,
.popup .actionLink.linkHover {
  text-decoration: none;

  color: inherit;
}

.popup .actionLink.linkHover {
  background-color: var(--ring-selected-background-color);
}

.popup .hint {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
  padding-block: unit;
}

.customLinkIcon {
  margin-right: unit;

  transition: color var(--ring-ease);

  color: var(--ring-icon-color);
}

.tryPipelinesLink {
  display: inline-flex;
}

.tryPipelinesNewTagWrapper {
  margin-left: unit;

  & > .tagContainer {
    display: inline-flex;
    align-items: baseline;
  }
}

@value resetButton from '../../../../ring-globals.css';
@value unit: var(--ring-unit);

.user {
  display: flex;

  padding: calc(unit * 2);
}

.userLoading {
  width: calc(unit * 8);
  height: calc(unit * 8);
}

.avatarWrapper {
  composes: resetButton;

  cursor: pointer;

  border-radius: 50%;
}

.avatarWrapper:hover {
  opacity: 0.84;
}

.avatarWrapper:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--ring-border-hover-color);
}

.avatar {
  margin: 0;
}

.guest {
  padding: 6px;

  border-radius: 50%;

  background-color: var(--ring-disabled-background-color);
}

.guest.guest svg {
  vertical-align: -5px;
}

.appearanceContainer.appearanceContainer {
  padding: 0;
}

.appearance {
  display: flex;

  padding: 3px calc(unit * 2) 5px;
}

.appearanceChevron {
  margin-left: auto;
  padding-left: calc(unit / 2);

  color: var(--ring-icon-color);
}

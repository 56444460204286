@value unit: var(--ring-unit);

.editButton {
  margin-bottom: calc(2 * unit);

  &:hover {
    text-decoration-line: none;

    color: var(--ring-text-color);
  }
}

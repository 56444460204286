@value unit: var(--ring-unit);

.notification {
  display: flex;
  align-items: flex-start;

  margin: 0 -10px;
  margin-bottom: calc(unit / 2);
  padding: calc(unit * 2) 10px;

  border-radius: unit;

  &:hover {
    background-color: var(--ring-tag-background-color);
  }
}

.unread {
  background-color: var(--ring-secondary-background-color);
}

.notificationIconWrapper {
  position: relative;

  box-sizing: border-box;
  width: calc(unit * 5);
  height: calc(unit * 5);
  margin-right: calc(unit * 1.5);
  padding: 10px;

  border-radius: 50%;
}

.notificationIcon {
  margin: 0 1px;

  line-height: normal;
}

.notificationIconWrapperError {
  background-color: var(--ring-removed-background-color);

  & .notificationIcon {
    color: var(--ring-icon-error-color);
  }
}

.notificationIconWrapperWarning {
  background-color: var(--ring-warning-background-color);

  & .notificationIcon {
    margin-top: -2px;

    color: var(--ring-icon-warning-color);
  }
}

.notificationIconWrapperInfo {
  background-color: var(--ring-disabled-background-color);
  box-shadow: 0 0 0 1px var(--ring-line-color) inset;

  & .notificationIcon {
    color: var(--ring-icon-secondary-color);
  }
}

.unreadDot {
  position: absolute;
  top: -3px;
  left: -3px;

  width: 10px;
  height: 10px;

  border-radius: 50%;
  background-color: var(--ring-main-color);
}

.notificationBodyWrapper {
  flex-grow: 1;
}

.notificationTitle {
  display: flex;
  align-items: baseline;

  margin-bottom: calc(unit / 2);

  font-weight: 600;
}

.actions {
  margin: 0 -6px 0 auto;
}

.notificationBody {
  & :global(.attentionComment),
  & :global(.attentionRed) {
    color: var(--ring-secondary-color);
  }
}

@value unit: var(--ring-unit);
@value heightHeader: calc(unit * 6);

.actionButton {
  padding: 0 calc(unit / 2);

  opacity: 0;
}

.controls {
  display: grid;

  align-content: space-between;

  width: calc(unit * 9);

  height: heightHeader;

  text-align: right;

  & .actionButton {
    transition: opacity var(--ring-ease);
  }
}

.controlButtons {
  gap: calc(unit / 2);
}

.container {
  padding: unit;

  border-radius: calc(unit * 1.5);

  background-color: var(--ring-sidebar-background-color);

  &:hover .actionButton {
    opacity: 1;
  }
}

.containerHeader {
  display: flex;

  align-items: start;
}

.runnerIconButton {
  width: heightHeader;
  height: heightHeader;

  border-radius: unit;

  background-color: var(--ring-tag-background-color);
}

.icon {
  padding-right: unit;

  color: var(--ring-icon-color);
}

.name {
  padding-bottom: calc(unit / 1.5);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-font-size-smaller);
}

.type {
  display: -webkit-box;
  overflow: hidden;

  max-height: 42px;

  margin-top: auto;

  text-overflow: ellipsis;
  word-break: break-word;

  color: var(--ring-text-color);

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.deleted {
  .runnerIconButton {
    background-color: var(--ring-content-background-color);
  }

  .icon {
    color: var(--ring-icon-disabled-color);
  }

  .controls {
    align-content: center;

    justify-content: end;
  }
}

.deleted .name,
.deleted .type {
  text-decoration: line-through;

  color: var(--ring-disabled-color);
}

.toggleControl {
  justify-self: flex-end;
}

.info {
  flex: 1;

  padding-top: calc(unit / 2);
}

.subName {
  display: inline-block;
}

.deleted .subName {
  text-decoration: line-through;

  color: var(--ring-disabled-color);
}

.subName::first-letter {
  text-transform: uppercase;
}

.details {
  display: -webkit-box;
  overflow: hidden;

  max-height: calc(unit * 20);

  margin: 0;
  padding: unit 0 0;

  text-overflow: ellipsis;
  word-break: break-word;

  color: var(--ring-secondary-color);

  font-family: var(--ring-font-family), serif;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-font-size-larger);

  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}

.error {
  padding-top: unit;

  color: var(--ring-error-color);

  font-size: var(--ring-font-size-smaller);
}

@value unit: var(--ring-unit);

.header {
  margin-bottom: calc(unit * 2);
}

.heading.heading {
  margin: 0 auto 0 0;
}

.heading.projectArchived .title {
  color: var(--ring-secondary-color);

  font-weight: normal;
}

.star {
  margin-left: -2px;

  vertical-align: 3px;
}

.branchFilter {
  flex: 0 0 auto;

  font-size: var(--ring-font-size-smaller);

  font-weight: normal;
  line-height: var(--ring-line-height-lowest);
}

.top {
  display: flex;

  min-height: calc(unit * 3.5);
}

.topSequenceLoader {
  display: flex;
  flex-direction: row-reverse;

  gap: calc(unit / 2);
}

.breadcrumbs {
  flex-grow: 1;
}

.breadcrumbsLink {
  font-size: var(--ring-font-size-smaller);
  line-height: 24px;
}

.subHeader {
  display: flex;
  align-items: baseline;

  width: 100%;
  margin-top: unit;
}

.branchFilter,
.createProject:not(:last-child) {
  margin-right: unit;
}

.description {
  margin-top: calc(unit / 2);
}

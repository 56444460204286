@value unit: var(--ring-unit);

.repoItem {
  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.repoName {
  display: inline-block;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;

  font-weight: 600;

  line-height: var(--ring-line-height-taller);
}

.nameRow {
  display: flex;
  align-items: center;
  column-gap: unit;
}

.repoLink {
  display: none;

  margin-left: auto;
  padding: 0;

  & svg {
    margin-left: calc(unit / 2);

    color: var(--ring-action-link-color);
  }

  &:active svg {
    color: var(--ring-main-color) !important;
  }

  &:hover svg {
    color: var(--ring-link-hover-color);
  }
}

.repoDescription {
  display: inline-block;
  overflow: hidden;

  width: 100%;

  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: var(--ring-font-size-smaller);
}

.icon {
  color: var(--ring-icon-color);
}

.repoItemButton {
  padding-top: 6px !important;

  &:hover .repoLink {
    display: inline-block;
  }
}

@value unit: var(--ring-unit);

.container {
  display: flex;
  justify-content: space-between;

  padding-top: calc(unit * 2);
  gap: calc(unit * 5);
}

.searchBar {
  margin: calc(unit * 2) 0 unit;
}

.noPrivateActionsMessage {
  margin-bottom: calc(unit * 3);

  color: var(--ring-secondary-color);
}

.newWindowLink {
  color: var(--ring-link-color);
}

@value unit: var(--ring-unit);

.label {
  padding: 0 calc(unit * 0.75);

  color: var(--ring-text-color);

  border-radius: calc(unit / 2);
  background-color: var(--ring-tag-background-color);
}

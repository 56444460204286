@value unit: var(--ring-unit);
@value duration: 300ms;
@value timing-function: cubic-bezier(0.23, 1, 0.32, 1);

.toggle {
  --toggle-background-color: var(--ring-tag-background-color);
  --switcher-background-color: var(--ring-white-text-color);
  --switcher-color: var(--ring-icon-color);
  --label-color: var(--ring-text-color);
  --toggle-hover-background: var(--ring-border-hover-color);

  position: relative;

  width: calc(unit * 7.5);
  margin-left: unit;
  padding: 0 unit;

  transition: background timing-function duration;

  border-radius: calc(unit * 3.5);
  background-color: var(--toggle-background-color);
  box-shadow: none;

  .switcher {
    position: absolute;
    z-index: 1;
    top: calc(unit / 4);
    left: calc(unit / 4);

    width: calc(unit * 3);
    height: calc(unit * 3);

    transition: transform timing-function duration;
    text-align: center;

    color: var(--switcher-color);
    border-radius: calc(unit * 3.5);
    background-color: var(--switcher-background-color);

    & svg {
      vertical-align: sub;
    }
  }

  .label {
    position: absolute;
    top: 20%;
    right: unit;

    transition: transform timing-function duration;

    color: var(--label-color);

    font-size: var(--ring-font-size-smaller);
    line-height: var(--ring-line-height-lowest);
  }

  &.checked {
    --toggle-background-color: var(--ring-main-color);
    --switcher-color: var(--ring-main-color);
    --label-color: var(--ring-white-text-color);

    box-shadow: none;

    .switcher {
      transform: translateX(calc(100% + 8px));
    }

    .label {
      transform: translateX(calc(-100%));
    }
  }

  &.checked:hover {
    --toggle-hover-background: var(--ring-main-hover-color);
    --label-color: var(--ring-white-text-color);
  }

  &.disabled.checked {
    --toggle-background-color: var(--ring-border-selected-disabled-color);
  }

  &.disabled {
    --toggle-background-color: var(--ring-disabled-background-color);
    --switcher-color: var(--ring-icon-disabled-color);
    --label-color: var(--ring-disabled-color);

    box-shadow: none;
  }

  &.disabled:hover {
    --label-color: var(--ring-disabled-color);
    --switcher-color: var(--ring-icon-disabled-color);

    background: var(--ring-disabled-background-color);
  }

  &:hover {
    --label-color: var(--ring-text-color);
    --switcher-color: var(--ring-main-color);

    background: var(--toggle-hover-background);
  }
}

.tooltipWrapper {
  display: flex;

  height: 28px;
}

@value unit: var(--ring-unit);

.row {
  position: relative;

  display: flex;
  overflow: hidden;
  align-items: baseline;
  flex: 1;
  flex-direction: row;

  padding: 0;

  cursor: pointer;

  line-height: var(--ring-line-height-lowest);

  & > * {
    margin-top: 0;
  }
}

.detailedRow {
  position: relative;

  display: flex;
  overflow: hidden;
  align-items: baseline;
  flex: 1;
  flex-direction: row;

  padding: 0;

  white-space: nowrap;

  font-size: var(--ring-font-size);

  line-height: var(--ring-line-height-lowest);

  & > * {
    margin-top: 0;
  }
}

.copyTestNameIcon.copyTestNameIcon {
  &:focus {
    opacity: 1;
  }
}

.row,
.detailedRow {
  & .copyTestNameIcon {
    opacity: 0;
  }

  &:hover .copyTestNameIcon {
    opacity: 1;
  }
}

.comment {
  margin-left: 0;
}

.column {
  overflow: hidden;
  flex-direction: column;

  width: 100%;
}

.leftPart {
  display: flex;
  overflow: hidden;
  align-items: baseline;
  flex: 1;

  padding-right: calc(unit * 4);
}

.testMutes,
.testInvestigations {
  flex: 0 0 auto;

  padding: 0;
}

.testMutesIcon,
.testInvestigationsIcon {
  margin-right: calc(unit / 2);
}

.name {
  overflow: hidden;

  margin: calc(unit / 2) 0;
}

.flakyLabel,
.testMetadataIcon {
  margin-left: unit;
  padding: 0;
}

.rightPart {
  white-space: nowrap;
}

.detailedRow .rightPart {
  display: inline-flex;
  align-items: baseline;

  width: 400px;

  white-space: nowrap;
}

.runCount {
  display: inline-block;

  margin-right: calc(unit * 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.actions {
  width: unset;
  padding: 0;
}

.newFailure {
  font-weight: bold;
}

.fixed {
  text-decoration-line: line-through;

  color: var(--ring-secondary-color);
}

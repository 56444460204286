@value unit: var(--ring-unit);

.button {
  text-align: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  text-align: center;

  color: var(--ring-icon-color);

  border-radius: calc(unit * 1.5);
  background-color: var(--ring-sidebar-background-color);
}

.icon.iconHover {
  cursor: pointer;

  &:hover {
    background-color: var(--ring-selected-background-color);
  }
}

.selected .icon {
  color: #fff;
  background: var(--ring-main-color);

  &:hover {
    background: var(--ring-main-hover-color);
  }
}

.label {
  overflow: hidden;

  min-width: 0;
  padding-top: calc(unit / 2);

  white-space: nowrap;

  text-transform: uppercase;
  text-overflow: ellipsis;

  color: var(--ring-text-color);

  font-size: 10px;
  line-height: 11px;
}

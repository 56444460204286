@value unit: var(--ring-unit);

.content {
  display: flex;

  margin-top: calc(unit / -4);
  padding-bottom: calc(unit / 4);
  column-gap: unit;
}

.button {
  padding: 0;

  font-size: var(--ring-font-size);
}

.deactivate {
  color: var(--ring-secondary-color);
}

.deactivationLink {
  margin-left: 1px;
}

.deactivationDialog {
  width: calc(unit * 75);
}

.deactivationWarning {
  display: flex;
  flex-direction: column;
  row-gap: unit;
}

.error {
  display: flex;
  column-gap: calc(unit / 2);

  height: calc(unit * 2);

  color: var(--ring-icon-error-color);
}

@value unit: var(--ring-unit);

.groupTitle {
  margin-top: calc(unit * 7 / 4);
  margin-right: calc(unit / 4);
  margin-left: -2px;

  color: var(--ring-icon-secondary-color);
}

.text {
  display: contents;

  color: var(--ring-heading-color);

  font-size: var(--ring-font-size);

  font-weight: bold;
}

.matches {
  margin-left: calc(var(--ring-unit) / 2);

  color: var(--ring-secondary-color);
}

.activeLink {
  text-decoration-line: none;

  color: var(--ring-text-color);
}

.link {
  display: flex;
  overflow: hidden;
}

.content {
  overflow: hidden;
  flex: 1 1 auto;

  padding-right: 8px;

  text-overflow: ellipsis;
}

.tail {
  flex: 0 0 auto;

  padding-right: 8px;

  color: var(--ring-secondary-color);

  font-weight: normal;
}
